<template>
  <div>
    <h1>This is dashboard..!</h1>
  </div>
</template>

<script>

export default {
  data() {
    return {
      // 
    }
  }
}
</script>